<template>
  <v-sheet class="pr-5">
    <v-sheet class="my-4" v-for="(item, i) in convo.messages" :key="i">
      <v-row no-gutters style="flex-wrap: nowrap">
        <v-col cols="1" class="d-flex justify-center flex-grow-0 flex-shrink-0">
          <div class="" v-if="type == 'student'">
            <v-avatar v-if="item.from == user.uuid && user.image" color="grey" :size="small ? '28' : '50'">
              <v-img :src="`${user.aws}/${user.model}/${user.uuid}/image/${user.image.image}`"/>            
            </v-avatar> 
            <v-avatar v-else color="grey" :size="small ? '28' : '50'">
              <v-img v-if="convo.image" :src="`${convo.aws}/${convo.model}/${convo.uuid}/image/${convo.image.image}`"/>          
            </v-avatar> 
          </div>
          
          <div v-else>
            <v-avatar v-if="item.from == user.uuid" color="grey" :size="small ? '28' : '50'">
              <v-img v-if="user.image" :src="`${user.aws}/${user.model}/${user.uuid}/image/${user.image.image}`"/>
            </v-avatar>
            <v-avatar v-else color="grey" :size="small ? '28' : '50'">
              <v-img v-if="convo.image" :src="`${convo.aws}/${convo.model}/${convo.uuid}/image/${convo.image.image}`"/>
            </v-avatar>
          </div>

           
        </v-col>
        <v-col :cols="small ? '12' : '11'" :class="small?'pr-5':''">
          <div class="d-flex my-2 justify-space-between align-center">
            <div class="body-2 font-weight-bold">
              {{item.from == user.uuid 
                ? user.firstname + ' ' + user.lastname
                : convo.firstname + ' ' + convo.lastname  
              }}
            </div>
            <div class="caption">{{ $helpers.dateDiff(item.created_at) }}</div>
          </div>
          <!-- :color="item.from == user.uuid ? '' : 'success'"  -->
          <!-- :dark="item..uufrom != userid" -->
          <v-sheet rounded="lg" outlined 
          :style="item.from == user.uuid ? 'border: 2px solid #00A14B' : ''"
          class="pa-3 body-2"
          v-html="item.message">                           
            
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  props: ['convo', 'user', 'type', 'small']
}
</script>
