<template>
  <div class="mx-5 mt-2 d-flex align-center">
    <div class="caption mr-2" 
    :class="data.status ? 'success--text' : 'grey--text'">
      User status is {{data.status ? 'online' : 'offline'}}
    </div>
    <div class="" v-if="!data.status">
      <v-tooltip top style="background: transparent !important">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            icon
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-information-outline
            </v-icon>
          </v-btn>
          
        </template>
        <v-card max-width="300">
          <v-card-text>
            <div class="mb-3">
              Good Day!
            </div>
            <div class="">
              Our Company representatives are not online at the moment. Kindly leave a message and we will get back to you at the soonest available time. You may also want to visit our virtual booth to know more about our available products and services.
            </div>
            <div class="mt-3">Thank you!</div>
          </v-card-text>
        </v-card>
      </v-tooltip>  
    </div>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>