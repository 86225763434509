<template>
  <v-container class="inbox">
    <v-row>
      <v-col cols="12" :xl="small ? '12' : '9'" :lg="small? '12' :'11'">
        <v-row no-gutters class="fill-height">
          <v-col cols="12" lg="3">
            <v-sheet outlined>
              <div class="success white--text d-flex px-3 justify-space-between align-center">
                <div class="ma-2 font-weight-bold"
                :class="small?'body-1' : 'text-h6'">Chat Now</div>
                <v-btn icon color="#fff">
                  <v-icon size="18"> mdi-dots-vertical </v-icon>
                </v-btn>
              </div>
              <v-divider></v-divider>
              <div class="mx-5">
                <v-text-field label="Search" />
              </div>
              <v-divider></v-divider>
              <Contacts :data="sponsors" @open="openConvo" :small="small"/>
              <!-- <infinite @distance="1" @infinite="handleLoadMore"></infinite> -->
            </v-sheet>
          </v-col>
          <v-col cols="12" lg="9" v-if="on_chat">
            <v-sheet outlined>
              <Info :data="chat"/>
              <v-sheet id="chatdrawer" color="transparent" class="inbox__container">
                <v-sheet class="pr-5">
                  <ChatBox :convo="chat" :user="user" type="student" :small="small"/>
                </v-sheet>
              </v-sheet>
              <Status :data="chat"/>
              <div class="ma-2">
                <Input @send="sendMsg" />
              </div>
            </v-sheet>
            
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
// import io from "socket.io-client";
import Pusher from 'pusher-js'
import Contacts from '../../teacher/components/inbox/Contacts.vue'
import ChatBox from '../../teacher/components/inbox/ChatBox.vue'
import Input from '../../teacher/components/inbox/Input.vue'
import Info from '../components/inbox/Info.vue'
import Status from '../components/inbox/Status.vue'

export default {
  props: ['small'],
  components: {
    Contacts,
    ChatBox,
    Info,
    Input,
    Status
  },
  data: () => ({
    socket: "",
    on_chat: false,
    data: [],
    load_onScroll: false,
    mate: '',
    container: '',
    first: true
  }),
  computed: {
    ...mapState('student', {
      sponsors: (state) => state.sponsors,
      user: (state) => state.user,
      chat: (state) => state.chat,
    })
  },
  mounted() {
    // let vm = this

    this.getSponsorsAction()
    this.subscribe()

    // this.socket = io.connect(this.$helpers.socket_end_point(), {});

    // this.socket.on("connect", () => {console.log("start connection");});

    // let iochat = `${this.user.uuid}:${this.$helpers.tenant()}-chat-channel:App\\Events\\ChatEvent`

    // this.socket.on(iochat, function (message) {
    //   vm.getSponsorsAction()
    //   if(vm.on_chat) {
    //     if(vm.mate != message.data.from) return
    //     vm.showChatAction(message.data.from).then(() => {
    //       vm.scrollBottom()
    //     });
    //   }
      
    // })
  },
  beforeDestroy() {
    this.on_chat = false
    this.chatMutation([])
  },
  methods: {
    ...mapActions("student", [
      "getSponsorsAction",
      "showChatAction",
      "sendChatAction",
    ]),

    ...mapMutations('student', [
      'chatMutation'
    ]),

    openConvo(e) {
      
      this.mate = e
      this.showChatAction(e).then(() => {
        this.on_chat = true
        this.scrollBottom()

      });
    },

     sendMsg(e) {
      
      this.sendChatAction({
        uuid: this.chat.uuid,
        message: e,
      }).then(() => {
        this.first = true
        this.scrollBottom()
      })      
    
    }, 

    scrollBottom() {
      if(this.first) {
        setTimeout(() => {
          this.container = this.$el.querySelector("#chatdrawer");
          this.container.scrollTop = this.container.scrollHeight;
        }, 1000);

        this.first = false
        return 
      }

      this.container.scrollTop = this.container.scrollHeight;
      
    },

    subscribe() {
      let vm = this
      let pusher = new Pusher('b16ba45013da47f593a8', { 
        cluster: 'ap1',
        encrypted: false
      })
      console.log(pusher);
      //Chat Channel
      let chat = pusher.subscribe(`1-chat-channel`)
      chat.bind('App\\Events\\ChatEvent', data => {
        const res = JSON.parse(JSON.stringify(data))
        // console.log(JSON.parse(res));
        vm.getSponsorsAction()
        if(vm.on_chat) {
          if(vm.mate != res.data.from) return
          vm.showChatAction(res.data.from).then(() => {
            vm.scrollBottom()
          });
        }
      })
    }
  }
}
</script>