<template>
  <section style="position: relative">
    <!-- <v-text-field
      v-model="message"
      append-icon="mdi-emoticon"
      append-outer-icon="mdi-send"
      filled
      dense
      single-line
      hide-details
      label="Message"
      type="text"
      @click:append="show=!show"
      @click:append-outer="send"
    ></v-text-field> -->

    <!-- :editorOptions="editorSettings" -->
    <vue-editor 
    v-model="message" 
    :editorToolbar="customToolbar"
    :editorOptions="editorSettings"
    />
    <div class="d-flex justify-end my-3">
      <v-btn @click="send" class="button" color="success">
        <v-icon left>
          mdi-send
        </v-icon>
        Send
      </v-btn>
    </div>
    <!-- <VEmojiPicker
      v-show="show"
      :style="{ 
        width: '440px', 
        position: 'absolute',
        right: '0px',
        top: '-430px',
      }"
      labelSearch="Search"
      lang="pt-BR"
      @select="onSelectEmoji"
    /> -->
  </section>
</template>

<script>
// import { VEmojiPicker } from "v-emoji-picker";
import {Quill} from 'vue2-editor'
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-vue";

Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

export default { 
  // components: {
  //   VEmojiPicker,
  // },
  data: () => ({
    message: '',
    show: false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image", "code-block"]
    ],
    // customModulesForEditor: [
    //   { alias: "imageDrop", module: ImageDrop },
    //   { alias: "imageResize", module: ImageResize }
    // ],
    editorSettings: {
      modules: {
        imageDrop: true,
        imageResize: true
      }
    }
  }),
  methods: {

    send() {
      console.log('send');
      this.$emit('send', this.message)
      this.message = ''
    },
    onSelectEmoji(emoji) {
      this.message += emoji.data;
      this.show = false;
    },
  }
}
</script>
